import React from "react";
import { SEO, PageLayout, HeroElement, Contact } from "@bluefin/components";
import { Image, Grid } from "semantic-ui-react";
import { graphql } from "gatsby";

import WebsiteLayout from "../components/WebsiteLayout.jsx";
import { getPageHeroImage } from "../utils/utils";

export default class IndexPage extends React.PureComponent {
  render() {
    const { data } = this.props;
    const { fishermanBusiness, fishermanBusinessWebsitePage } = data;

    return (
      <WebsiteLayout>
        <SEO
          businessName={fishermanBusiness.name}
          pageName={fishermanBusinessWebsitePage.title}
          images={getPageHeroImage({
            options: fishermanBusinessWebsitePage.components,
          })}
        />
        <PageLayout
          hero={
            <HeroElement
              header={""}
              tagline={
                <div>
                  <Image
                    className={"hero-logo"}
                    src={
                      "https://fisherman.gumlet.io/public/freebirds/FreeBirds.png"
                    }
                  />
                  <h1 className={"hero-text"}>Isla Vista, California</h1>
                </div>
              }
              images={getPageHeroImage({
                options: fishermanBusinessWebsitePage.components,
              })}
              ctas={[
                {
                  link: "https://freebirdsiv.namer.alohaonlineordering.com/Engage.aspx?#/engage/ordering/menu",
                  text: "Order Online",
                  internal: false,
                },
              ]}
              ctaInverted={true}
              ctaColor={"white"}
              containerColor={"primary"}
              textColor={"white"}
              showMultiple={true}
              overlay={true}
              fullWidth={true}
              parallax={false}
              containerAs={"none"}
              height={500}
              gutter={false}
            />
          }
          subfooter={false}
        >
          <Grid stackable={true} className={"component-section-container"}>
            <Grid.Column width={16}>
              <Contact
                businessName={fishermanBusiness.name}
                phoneNumber={fishermanBusiness.primaryLocation.phoneNumber}
                email={fishermanBusiness.primaryLocation.email}
                hours={fishermanBusiness.primaryLocation.hours}
                address={{
                  street: fishermanBusiness.primaryLocation.street,
                  city: fishermanBusiness.primaryLocation.city,
                  state: fishermanBusiness.primaryLocation.state,
                  zip: fishermanBusiness.primaryLocation.zipCode,
                }}
                as={"text"}
                buttonColor={"secondary"}
                header={"Contact"}
                image={""}
                showMap={true}
              />
            </Grid.Column>
          </Grid>
        </PageLayout>
      </WebsiteLayout>
    );
  }
}

export const query = graphql`
  query {
    fishermanBusiness {
      name
      primaryLocation {
        phoneNumber
        email
        hours {
          open
          close
          label
          day
        }
        street
        city
        state
        zipCode
      }
    }
    fishermanBusinessWebsitePage(title: { eq: "Home" }) {
      title
      components {
        fastId
        order
        files {
          file
          altText
          gatsbyFile {
            childImageSharp {
              gatsbyImageData(layout: FULL_WIDTH)
            }
          }
        }
      }
    }
  }
`;
